<template>
  <el-dialog
    title="重置资金密码"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <div class="personalCenterContainer">
      <div class="setPassword">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px">
          <el-form-item label="登录账号">
            <el-input disabled type="text" v-model="form.loginAccount">
            </el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input :type="newPwdText" v-model="form.password">
              <img
                slot="suffix"
                :src="closeEye"
                v-if="passNewType"
                @click="showPwd1"
              />
              <img slot="suffix" :src="openEye" v-else @click="hidePwd1" />
            </el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="passwordSecond">
            <el-input :type="newAginPwdText" v-model="form.passwordSecond">
              <img
                slot="suffix"
                :src="closeEye"
                v-if="newPwdType"
                @click="showPwd2"
              />
              <img slot="suffix" :src="openEye" v-else @click="hidePwd2" />
            </el-input>
          </el-form-item>
          <el-form-item label="短信验证码" prop="code" class="whiteEleLabel">
            <el-input
              class="eleInputText msgYzmInput"
              placeholder="请输入短信验证码"
              v-model="form.code"
            >
              <el-button
                @click="getMsgYzm"
                :disabled="btnDisenable"
                slot="append"
                class="btnGetYzm"
                >{{ btnMsgYzmVal }}</el-button
              >
            </el-input>
          </el-form-item>
          <div class="submitSetPassword">
            <el-button type="primary" @click="submitSetPassword()"
              >提交</el-button
            >
          </div>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import md5 from "js-md5";
export default {
  data() {
    var validatePassword = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      btnDisenable: false,
      captchaData: "",
      btnMsgYzmVal: "获取验证码",
      dialogVisible: false,
      loginAccount: null,
      oldPwdText: "password",
      newPwdText: "password",
      newAginPwdText: "password",
      PassOldtype: true,
      passNewType: true,
      newPwdType: true,
      closeEye: require("../../../../assets/images/closeeye.png"),
      openEye: require("../../../../assets/images/openeye.png"),
      form: {
        code: "",
        loginAccount: "",
        password: null,
        passwordSecond: null
      },
      rules: {
        captchaCode: [
          { required: true, message: "请输入图形验证码", trigger: "change" }
        ],
        code: [
          { required: true, message: "请输入短信验证码", trigger: "change" }
        ],
        password: [
          {
            required: true,
            message: "请输入资金密码",
            trigger: "blur"
          },
          {
            min: 6,
            max: 6,
            message: this.$t("请输入6位数字"),
            trigger: "blur"
          },
          {
            pattern: /^[0-9]{6,6}$/,
            message: this.$t("请输入6位数字")
          }
        ],
        passwordSecond: [
          {
            required: true,
            message: "请再次输入资金密码",
            trigger: "blur"
          },
          {
            min: 6,
            max: 6,
            message: this.$t(
              // "message.Please_enter_8_20_characters_numbers_or_punctuation"
              "请输入6位数字"
            ),
            trigger: "blur"
          },
          {
            pattern: /^[0-9]{6,6}$/,
            message: this.$t("请输入6位数字")
          },
          { validator: validatePassword, trigger: "change" }
        ]
        // (?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{8,20}$
      }
      // dialogVisible: false
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {},
  methods: {
    submitForm() {},
    // VerificationCode: function() {
    //   http.getRes(protocol.param_getCaptcha).then(response => {
    //     if (response.data) {
    //       this.captchaData =
    //         "data:image/png;base64," + response.data.value.data;
    //       // 图片校验时的参数
    //       protocol.param_forgetLoginPwd.param.captchaId =
    //         response.data.value.id;
    //     } else {
    //       this.$EL_MESSAGE(response.data);
    //     }
    //   });
    // },
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.btnMsgYzmVal = "重新发送" + e + "秒";
      if (e > 0) {
        setTimeout(function() {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.btnMsgYzmVal = vueThis.$t("message.Get_verification_code");
        vueThis.btnDisenable = false;
      }
    },
    // 发送验证码
    getMsgYzm() {
      if (this.form.loginAccount == "") {
        this.$EL_MESSAGE("请输入登录账号");
      }
      protocol.param_sendMes.param.cellphone = this.form.loginAccount;
      http.postFront(protocol.param_sendMes).then(response => {
        let { code, msg, values } = response.data;
        this.$EL_MESSAGE(response.data.message);
        this.$options.methods.setTime(60, this);
        this.btnDisenable = true;
      });
    },
    showDig() {
      const that = this;
      //   this.VerificationCode();
      this.form.loginAccount = sessionStorage.getItem("sessionTel");
      this.dialogVisible = true;
      this.$nextTick(() => {
        that.$refs["form"].resetFields();
      });
    },
    handleClose() {
      this.dialogVisible = false;
      (this.form.password = null), (this.form.passwordSecond = null);
    },
    // showDialog() {
    //   this.dialogVisible = true;
    // },
    showPwd() {
      this.PassOldtype = false;
      this.oldPwdText = "text";
    },
    hidePwd() {
      this.PassOldtype = true;
      this.oldPwdText = "password";
    },
    showPwd1() {
      this.passNewType = false;
      this.newPwdText = "text";
    },
    hidePwd1() {
      this.passNewType = true;
      this.newPwdText = "password";
    },
    showPwd2() {
      this.newPwdType = false;
      this.newAginPwdText = "text";
    },
    hidePwd2() {
      this.newPwdType = true;
      this.newAginPwdText = "password";
    },
    submitSetPassword() {
      console.log(this.form);
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocol.param_forgetPws.param.cellphone = this.form.loginAccount;
          protocol.param_forgetPws.param.code = this.form.code;
          protocol.param_forgetPws.param.newPassWord = md5(
            "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=" + this.form.password
          );
          http.postFront(protocol.param_forgetPws).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.dialogVisible = false;
              this.$refs["form"].resetFields();
              // this.dialogVisible = false;
              this.$EL_MESSAGE(message);
              var obj = {};
              obj.name = "";
              //   this.$store.commit("setHeaderText", obj);
              //   this.$router.push("/login");
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-dialog {
  border-radius: 8px;
}
/deep/ .el-button--primary {
  background: #2a5dfb;
}
/deep/ .el-dialog__body {
  padding: 0 20px;
}
/deep/ .el-form-item__label {
  text-align: left;
}
.accountSafeTitle {
  font-size: 16px;
  padding: 15px 0 5px 15px;
  border-bottom: 1px solid #eeeeee;
}
.setPassword {
  width: 100%;
  margin-top: 20px;
  padding: 15px;
  box-sizing: border-box;
}
.el-input {
  // width: 60%!important;
}
.personalCenterContainer {
  background-color: #fff;
  height: 100%;
  //   width: 1200px;
  margin: 0 auto;
  line-height: 32px;
  //   margin-top: 20px;
}
.personalCenterContainer .personalCenterTitle {
  font-size: 16px;
  border-bottom: 1px solid #eeeeee;
}
.personalCenterContainer .personalCenterTitle span {
  display: inline-block;
  padding: 0 15px;
  border-bottom: 2px solid $commonThemeColor;
}
.submitSetPassword {
  text-align: center;
  // margin-left: 320px;
}
.titles {
  padding: 15px;
  font-size: 18px;
}
.tupian {
  position: absolute;
  margin: auto;
  right: 8px;
  top: 3px;
  width: 97px;
  height: 34px;
}
</style>
