<template>
  <el-dialog
    title="登录密码修改"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <div class="personalCenterContainer">
      <div class="setPassword">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px">
          <el-form-item label="登录账号">
            <el-input disabled type="text" v-model="form.loginAccount">
            </el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input :type="newPwdText" v-model="form.password">
              <img
                slot="suffix"
                :src="closeEye"
                v-if="passNewType"
                @click="showPwd1"
              />
              <img slot="suffix" :src="openEye" v-else @click="hidePwd1" />
            </el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="passwordSecond">
            <el-input :type="newAginPwdText" v-model="form.passwordSecond">
              <img
                slot="suffix"
                :src="closeEye"
                v-if="newPwdType"
                @click="showPwd2"
              />
              <img slot="suffix" :src="openEye" v-else @click="hidePwd2" />
            </el-input>
          </el-form-item>
          <el-form-item label="短信验证码" prop="code" class="whiteEleLabel">
            <el-input
              class="eleInputText msgYzmInput"
              placeholder="请输入短信验证码"
              v-model="form.code"
            >
              <el-button
                @click="getMsgYzm"
                :disabled="btnDisenable"
                slot="append"
                class="btnGetYzm"
                >{{ btnMsgYzmVal }}</el-button
              >
            </el-input>
          </el-form-item>
          <el-form-item
            prop="captchaCode"
            label="图形验证码"
            style="position:relative"
          >
            <el-input
              type="text"
              class="eleInputLogin"
              v-model="form.captchaCode"
              placeholder="请输入图形验证码"
            ></el-input>
            <img
              :src="captchaData"
              class="tupian"
              @click="VerificationCode()"
            />
          </el-form-item>
          <div class="submitSetPassword">
            <el-button type="primary" @click="submitSetPassword()"
              >提交</el-button
            >
          </div>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import md5 from "js-md5";
export default {
  data() {
    var validatePassword = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      btnDisenable: false,
      captchaData: "",
      btnMsgYzmVal: "获取验证码",
      dialogVisible: false,
      loginAccount: null,
      oldPwdText: "password",
      newPwdText: "password",
      newAginPwdText: "password",
      PassOldtype: true,
      passNewType: true,
      newPwdType: true,
      closeEye: require("../../../../assets/images/closeeye.png"),
      openEye: require("../../../../assets/images/openeye.png"),
      form: {
        code: "",
        loginAccount: "",
        password: null,
        passwordSecond: null,
        captchaCode: ""
      },
      rules: {
        captchaCode: [
          { required: true, message: "请输入图形验证码", trigger: "change" }
        ],
        code: [
          { required: true, message: "请输入短信验证码", trigger: "change" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
          {
            // pattern:  /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[])+$)([^(0-9a-zA-Z)]|[]|[a-z]|[A-Z]|[0-9]|[\()]){8,20}$/,
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/,
            message: "请输入长度是8-20位的数字、字母组合密码",
            trigger: "change"
          }
        ],
        passwordSecond: [
          { required: true, message: "请再次输入密码", trigger: "change" },
          {
            // pattern: /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[])+$)([^(0-9a-zA-Z)]|[]|[a-z]|[A-Z]|[0-9]|[\()]){8,20}$/,
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/,
            message: "请输入长度是8-20位的数字、字母组合密码",
            trigger: "change"
          },
          { validator: validatePassword, trigger: "change" }
        ]
        // (?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{8,20}$
      }
      // dialogVisible: false
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    this.VerificationCode();
  },
  methods: {
    submitForm() {},
    VerificationCode: function() {
      http.getRes(protocol.param_getCaptcha).then(response => {
        if (response.data) {
          this.captchaData =
            "data:image/png;base64," + response.data.value.data;
          // 图片校验时的参数
          protocol.param_forgetLoginPwd.param.captchaId =
            response.data.value.id;
        } else {
          this.$EL_MESSAGE(response.data);
        }
      });
    },
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.btnMsgYzmVal = "重新发送" + e + "秒";
      if (e > 0) {
        setTimeout(function() {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.btnMsgYzmVal = vueThis.$t("message.Get_verification_code");
        vueThis.btnDisenable = false;
      }
    },
    // 发送验证码
    getMsgYzm() {
      if (this.form.loginAccount == "") {
        this.$EL_MESSAGE("请输入登录账号");
      }
      protocol.param_authn_authn_sendCaptcha.param.loginAccount = this.form.loginAccount;
      protocol.param_authn_authn_sendCaptcha.param.id = 2;
      protocol.param_authn_authn_sendCaptcha.param.mark = null;
      http.postFront(protocol.param_authn_authn_sendCaptcha).then(response => {
        let { code, msg, values } = response.data;
        this.$EL_MESSAGE(response.data.message);
        this.$options.methods.setTime(60, this);
        this.btnDisenable = true;
      });
    },
    showDig() {
      const that = this;
      this.VerificationCode();
      this.form.loginAccount = sessionStorage.getItem("sessionTel");
      this.dialogVisible = true;
      this.$nextTick(() => {
        that.$refs["form"].resetFields();
      });
    },
    handleClose() {
      this.dialogVisible = false;
      (this.form.password = null), (this.form.passwordSecond = null);
    },
    // showDialog() {
    //   this.dialogVisible = true;
    // },
    showPwd() {
      this.PassOldtype = false;
      this.oldPwdText = "text";
    },
    hidePwd() {
      this.PassOldtype = true;
      this.oldPwdText = "password";
    },
    showPwd1() {
      this.passNewType = false;
      this.newPwdText = "text";
    },
    hidePwd1() {
      this.passNewType = true;
      this.newPwdText = "password";
    },
    showPwd2() {
      this.newPwdType = false;
      this.newAginPwdText = "text";
    },
    hidePwd2() {
      this.newPwdType = true;
      this.newAginPwdText = "password";
    },
    submitSetPassword() {
      console.log(this.form);
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocol.param_forgetLoginPwd.param.loginAccount = this.form.loginAccount;
          protocol.param_forgetLoginPwd.param.captchaCode = this.form.captchaCode;
          protocol.param_forgetLoginPwd.param.code = this.form.code;
          protocol.param_forgetLoginPwd.param.password = md5(
            "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=" + this.form.password
          );
          http.postFront(protocol.param_forgetLoginPwd).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.dialogVisible = false;
              this.$refs["form"].resetFields();
              // this.dialogVisible = false;
              this.$EL_MESSAGE(message);
              var obj = {};
              obj.name = "";
              this.$store.commit("setHeaderText", obj);
              this.$router.push("/login");
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-form-item__label {
  text-align: left;
}
.accountSafeTitle {
  font-size: 16px;
  padding: 15px 0 5px 15px;
  border-bottom: 1px solid #eeeeee;
}
.setPassword {
  width: 100%;
  margin-top: 20px;
  padding: 15px;
  box-sizing: border-box;
}
.el-input {
  // width: 60%!important;
}
.personalCenterContainer {
  background-color: #fff;
  height: 100%;
  //   width: 1200px;
  margin: 0 auto;
  line-height: 32px;
  margin-top: 20px;
}
.personalCenterContainer .personalCenterTitle {
  font-size: 16px;
  border-bottom: 1px solid #eeeeee;
}
.personalCenterContainer .personalCenterTitle span {
  display: inline-block;
  padding: 0 15px;
  border-bottom: 2px solid $commonThemeColor;
}
.submitSetPassword {
  text-align: center;
  // margin-left: 320px;
}
.titles {
  padding: 15px;
  font-size: 18px;
}
.tupian {
  position: absolute;
  margin: auto;
  right: 8px;
  top: 3px;
  width: 97px;
  height: 34px;
}
</style>
