<template>
  <div class="presonageModule">
      <div class="title">个人设置</div>
      <div class="pwdItems">
          <div class="left">
              <div class="lImg">
                  <img src="../../../../assets/images/aside/dz.png" alt="">
              </div>
              <div class="lMes">
                  <div>地址管理</div>
                  <div style="color: #ccc">设置收货地址</div>
              </div>
          </div>
          <div class="right" @click="goPage1">设置</div>
      </div>
  </div>
</template>

<script>
export default {
    methods: {
        goPage1() {
            this.$router.push('/address')
        }
    }
}
</script>

<style lang="scss" scoped>
.presonageModule {
    border: 1px solid #E6ECF2;
    width: 1200px;
    margin: 0 auto;
.title {
        background-color: #F2F6FA;
        border-bottom: 1px solid #E6ECF2;
        padding: 10px;
    }
    .pwdItems {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        .left {
            height: 50px;
            display: flex;
            align-items: center;
            .lImg {
                height: 100%;
                margin-right: 15px;
                display: flex;
                align-items: center;
                img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }
            }
            .lMes {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
            }
        }
        .right {
            color: $commonThemeColor;
            cursor: pointer;
        }
    }
}
</style>