<template>
  <div class="authenticationModule">
    <!-- <div style="font-size: 18px">当前账号认证状态:</div>
    <div>{{ text }}</div>
    <div>
      <span class="colorFont" v-if="text == '未认证'" @click="goPage"
        >身份认证</span
      >
      <span @click="goPage" class="colorFont" v-else>查看详情</span>
    </div> -->
    <div class="titles">
        <img src="../../../../assets/images/header/tx.png" alt="" srcset="" />
        <span class="userName">{{ firmName }}</span>
        <span class="rzzt" :class="text == '已认证' ? 'yrz' : '' " >{{ text }}</span>
      </div>
    <el-dialog id="el_dig3" :visible.sync="identityFlag" width="40%">
      <identityModules @cancel="cancelDia_3" @popupEnter="popupEnter" />
    </el-dialog>
  </div>
</template>

<script>
import identityModules from "@/components/identityModule/identityModules";

import { mapState, mapGetters } from "vuex";
export default {
  components: {
    identityModules
  },
  data() {
    return {
      firmName: sessionStorage.getItem("userName"),
      auditType: {
        auditType: ""
      },
      identityFlag: false,
      text: "查询中..."
    };
  },
  created() {
    const that = this;
    this.$nextTick(() => {
      that.getIdentificationStatus();
    });
  },
  computed: {
    ...mapGetters(["approveFlagGetter"])
  },
  watch: {
    approveFlagGetter() {
      this.getIdentificationStatus();
    }
  },
  methods: {
    popupEnter() {
      this.identityFlag = false;
      this.$router.push("/personalEnterpriseAuth");
    },
    cancelDia_3() {
      this.identityFlag = false;
    },
    goPage() {
      // 查询认证类型
      http.postFront(protocol.param_queryCertificationType).then(response => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          this.auditType = value;
          if (value && value.auditType) {
            if (value.auditType === "P") {
              // 个人,查询百度认证
              this.getBDcertifiy1();
              // this.$router.push("/personalEnterpriseAuth");
            } else if (value.auditType === "O") {
              // 企业,查询实名认证
              this.queryAuditByUserId();
              // this.$router.push("/personalEnterpriseAuth");
            }
          } else {
            // 未选择认证类型
            this.identityFlag = true;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });

      // this.$router.push("/personalEnterpriseAuth");
    },
    // 查询百度认证(不带流程用)
    getBDcertifiy1() {
      http
        .postFront(protocol.param_getIdentificationInfo)
        .then(response => {
          const { code, message, value } = response.data;
          this.isBdRetun = true;
          if (code === "0" || code === 0) {
            if (
              value !== null &&
              JSON.stringify(value) !== "{}" &&
              value !== "0"
            ) {
              // 百度已认证,进入实名认证页面
              console.log("百度已认证,进入实名认证页面");
              this.BDcertifiy = "已认证";
              this.$router.push("/personalEnterpriseAuth");
            } else {
              // 百度未认证,打开选择认证方式弹窗
              console.log("未认证,打开选择认证方式弹窗");
              this.BDcertifiy = "未认证";
              this.$router.push("/certificationTypes");
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 实名认证查询(不带流程用)

    queryAuditByUserId() {
      http
        .postFront(protocolFwd.param_findBaseUserAuditByUserId)
        .then(response => {
          const { code, message, value } = response.data;
          if (Number(code) === 0) {
            if (value == null) {
              // 实名认证未通过,打开认证方式选择弹窗
              // 判断是个人还是企业
              if (this.auditType) {
                if (this.auditType.auditType === "P") {
                  // 个人
                  this.$router.push("/personalEnterpriseAuth");
                } else {
                  // 企业
                  this.$router.push("/certificationTypes");
                }
              }
            } else {
              this.$router.push("/personalEnterpriseAuth");
            }
          } else {
            this.$router.push("/personalEnterpriseAuth");
          }
        });
    },
    // 认证状态查询
    getIdentificationStatus() {
      //   http
      //     .postFront(protocolFwd.param_findBaseUserAuditByUserId)
      //     .then(response => {
      //   const { code, message, value } = response.data;
      //   if (Number(code) === 0) {
      // this.datas = value;
      let value = this.approveFlagGetter;
      if (value == null) {
        this.text = "未认证";
      } else {
        // 认证类型（O:组织认证 P:个人认证）
        if (value.auditType == "O") {
        } else {
        }
        // 审核状态（0:未审核 1:已认证 2:已驳回）
        if (value.auditStatus == 0) {
          this.text = "未审核";
        } else if (value.auditStatus == 1) {
          this.text = "已认证";
        } else if (value.auditStatus == 2) {
          this.text = "已驳回";
        }
      }
      //   } else {
      //     this.$EL_MESSAGE(message);
      //   }
      // });
    }
  }
};
</script>

<style scoped lang="scss">
.titles {
  display: flex;
  align-items: center;
  padding: 15px;
  font-size: 18px;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 28px;
  margin-bottom: 36px;
  img {
    width: 60px;
  }
  .userName {
    font-weight: bold;
    font-size: 24px;
    color: #333333;
    margin-left: 20px;
    margin-right: 12px;
  }
  .rzzt {
    background: #f2f3f8;
    border-radius: 13px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    font-size: 16px;
    color: #999999;
    padding: 6px 20px;
  }
  .yrz{
    color: #2F61FB;
    background: #DFE4FF;
  }
}
/deep/ #el_dig3 .el-dialog {
  overflow-y: auto;
  max-height: 95%;
}
/deep/ #el_dig3 .el-dialog__body {
  padding: 0 !important;
}
.authenticationModule {
  // margin: 15px 0;
  // // border: 1px solid #e6ecf2;
  // height: 130px;
  // width: 100%;
  // // background: url("../../../../assets/images/aside/rz.png") 900px no-repeat;
  // // background-size: 253px 96px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-evenly;
  // padding-left: 20px;
  // overflow: hidden;
  // box-sizing: border-box;
}
.colorFont {
  color: $commonThemeColor;
  cursor: pointer;
}
</style>
