<template>
  <div>
    <div class="userOrSafety">
      <!-- <div class="top">
        <div class="topMes">
          <div class="mesModule">
            <div class="imgs">
              <img src="../../../assets/images/aside/tx.svg" alt="" />
            </div>
            <div class="phone">Hi, {{ name }}</div>
          </div>
        </div>
      </div> -->
      <div class="bottom">
        <!-- 认证模块 v-if="isAssets" -->
        <authenticationModule />
        <!-- 安全密码管理 -->
        <asfetypwd />
        <!-- 个人设置 -->
        <!-- <presonageModule /> -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import identityModules from "@/components/identityModule/identityModules";

import authenticationModule from "./components/authenticationModule";
import asfetypwd from "./safetypwd";
import presonageModule from "./components/presonageModule";
import Footer from "@/components/base/footer/footerZYQ";
export default {
  components: {
    authenticationModule,
    asfetypwd,
    Footer,
    identityModules,
    presonageModule
  },
  data() {
    return {
      identityFlag: false,
      userMes: null,
      isAssets: true,
      name: ""
    };
  },
  created() {
    console.log(this.$store.state.common.sessionInfo, "实名信息");
    if (
      sessionStorage.getItem("sessionStr") &&
      sessionStorage.getItem("userId")
    ) {
      this.getIdentificationStatus();
    }
    var arr = this.$store.state.search.firmList;
    if (arr.length == 0) {
      this.isAssets = false;
    }
  },
  methods: {
    goPath() {
      // 查询认证类型
      http.postFront(protocol.param_queryCertificationType).then(response => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          if (value && value.auditType) {
            if (value.auditType === "P") {
              // 个人
              this.$router.push("/personalEnterpriseAuth");
            } else if (value.auditType === "O") {
              // 企业
              this.$router.push("/personalEnterpriseAuth");
            }
          } else {
            // 未选择认证类型
            this.identityFlag = true;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    popupEnter() {
      this.identityFlag = false;
      this.$router.push("/personalEnterpriseAuth");
    },
    cancelDia_3() {
      this.identityFlag = false;
    },
    getIdentificationStatus() {
      http
        .postFront(protocolFwd.param_findBaseUserAuditByUserId)
        .then(response => {
          const { code, message, value } = response.data;
          if (Number(code) === 0) {
            this.$store.commit("setApproveFlag", value);
            this.userMes = this.$store.state.common.sessionInfo;
            if (value == null) {
              this.name = this.userMes.tel;
            } else {
              console.log("进入");
              if (value.auditStatus == 1) {
                // 已通过认证
                // 认证类型（O:组织认证 P:个人认证）
                if (value.auditType == "O") {
                  this.name = value.name;
                } else {
                  this.name = value.name;
                }
              } else {
                this.name = this.userMes.tel;
              }
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.userOrSafety {
  height: calc(100vh - 275px);
  // min-height: 600px;
  background-color: #F1F5F8;
  .top {
    height: 120px;
    background-color: #1b2945;
    display: flex;
    align-items: center;
    .topMes {
      overflow: hidden;
      width: 1200px;
      margin: 0 auto;
      .mesModule {
        display: flex;
        align-items: center;
        .imgs {
          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }
        .phone {
          margin-left: 15px;
          color: #fff;
          font-size: 24px;
        }
      }
    }
  }
  .bottom {
    width: 1200px;
    margin: 0 auto;
  }
}
</style>
