<template>
  <el-dialog
    title="资金密码修改"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <div class="modifyMarginPwd">
      <div style="padding: 15px">
        <div class="modifyMarginPwdTitle">
          <!-- <div class="myModifyMarginPwd">
            <p v-if="isQyBank == 1 ||isQyBank == 13">修改资金密码<span class="tip">&nbsp;&nbsp;&nbsp;&nbsp;温馨提示：密码修改后10分钟后生效</span></p> 
            <p class="tip" v-else @click="goBinkPage">温馨提示：请先去银行签约</p> 
          </div> -->
        </div>
        <!-- v-if="isQyBank == 1 ||isQyBank == 13" -->
        <div class="modifyMarginPwdContent">
          <el-form ref="form" :model="form" :rules="rules" label-width="100px">
            <el-form-item label="旧密码" prop="oldMarginPwd">
              <el-input v-model="form.oldMarginPwd" :type="oldPwdText">
                <img
                  slot="suffix"
                  :src="closeEye"
                  v-if="PassOldtype"
                  @click="showPwd"
                />
                <img slot="suffix" :src="openEye" v-else @click="hidePwd" />
              </el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newMarginPwd">
              <el-input v-model="form.newMarginPwd" :type="newPwdText">
                <img
                  slot="suffix"
                  :src="closeEye"
                  v-if="passNewType"
                  @click="showPwd1"
                />
                <img slot="suffix" :src="openEye" v-else @click="hidePwd1" />
              </el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="rePwd">
              <el-input v-model="form.rePwd" :type="newAginPwdText">
                <img
                  slot="suffix"
                  :src="closeEye"
                  v-if="newPwdType"
                  @click="showPwd2"
                />
                <img slot="suffix" :src="openEye" v-else @click="hidePwd2" />
              </el-input>
            </el-form-item>
            <div style="display:flex;justify-content: flex-end;">
              <span @click="goReset" style="cursor:pointer">重置资金密码</span>
            </div>
            <!-- <el-form-item
              label="短信验证码"
              prop="code"
              class="whiteEleLabel"
            >
              <el-input
                class="eleInputText msgYzmInput"
                placeholder="请输入短信验证码"
                v-model="form.code"
              >
                <el-button
                  @click="getMsgYzm('form')"
                  :disabled="btnDisenable"
                  slot="append"
                  class="btnGetYzm"
                  :class="{ enBtnGetYzm: isEnBtnGetYzm }"
                  >{{ btnMsgYzmVal }}</el-button
                >
              </el-input>
            </el-form-item> -->
            <div class="submitButton">
              <el-button type="primary" @click="submitApply()">提交</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import md5 from "js-md5";
export default {
  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.form.newMarginPwd) {
        callback(new Error("确认密码和新密码不一致"));
      } else {
        callback();
      }
    };
    return {
      btnMsgYzmVal: "获取验证码",
      dialogVisible: false,
      form: {
        oldMarginPwd: "",
        newMarginPwd: "",
        rePwd: "",
        code: ""
      },
      rules: {
        code: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: "blur"
          }
          // { validator: validateMsgCaptcha, trigger: 'blur' }
        ],
        oldMarginPwd: [
          { required: true, message: "请输入旧密码", trigger: "blur" }
        ],
        newMarginPwd: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            pattern: /^\d{6}$/,
            message: "请输入6位纯数字密码",
            trigger: "blur"
          }
        ],
        rePwd: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: validatePassCheck, trigger: "blur" }
        ]
      },
      oldPwdText: "password",
      newPwdText: "password",
      newAginPwdText: "password",
      PassOldtype: true,
      passNewType: true,
      newPwdType: true,
      isQyBank: "",
      closeEye: require("../../../../assets/images/closeeye.png"),
      openEye: require("../../../../assets/images/openeye.png")
    };
  },
  mounted() {
    // this.getSignInfo()
  },
  methods: {
    goReset() {
      this.dialogVisible = false;
      this.$emit("goReset");
    },
    // 发送验证码
    getMsgYzm(formName) {
      protocol.param_capitalMesSend.param.cellphone = sessionStorage.getItem(
        "sessionTel"
      );
      http.postFront(protocol.param_capitalMesSend).then(response => {
        let { code, msg, values } = response.data;
        this.$EL_MESSAGE(response.data.message);
        this.$options.methods.setTime(60, this);
        this.btnDisenable = true;
      });
    },
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.btnMsgYzmVal = "重新发送" + e + "秒";
      if (e > 0) {
        setTimeout(function() {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.btnMsgYzmVal = vueThis.$t("message.Get_verification_code");
        vueThis.btnDisenable = false;
      }
    },
    showDig() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      (this.form.oldPassword = null),
        (this.form.password = null),
        (this.form.passwordSecond = null);
    },
    goBinkPage() {
      this.$router.push("/sign");
    },
    showPwd() {
      this.PassOldtype = false;
      this.oldPwdText = "text";
    },
    hidePwd() {
      this.PassOldtype = true;
      this.oldPwdText = "password";
    },
    showPwd1() {
      this.passNewType = false;
      this.newPwdText = "text";
    },
    hidePwd1() {
      this.passNewType = true;
      this.newPwdText = "password";
    },
    showPwd2() {
      this.newPwdType = false;
      this.newAginPwdText = "text";
    },
    hidePwd2() {
      this.newPwdType = true;
      this.newAginPwdText = "password";
    },
    // 签约信息查询
    getSignInfo() {
      http.postFront(protocolFwd.param_queryUserSign).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.isQyBank = value.signFlag;
        } else if (Number(code) == -180642) {
          this.isQyBank = 0;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    submitApply() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocolFwd.param_updateFundPwdEnd.param.oldPwd = md5(
            "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=" +
              this.form.oldMarginPwd
          );
          protocolFwd.param_updateFundPwdEnd.param.newPassWord = md5(
            "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=" +
              this.form.newMarginPwd
          );
          // protocolFwd.param_updateFundPwdEnd.param.code = this.form.code
          http.postFront(protocolFwd.param_updateFundPwdEnd).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.dialogVisible = false;
              this.$EL_MESSAGE(message);
              this.$refs["form"].resetFields();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
/deep/ .el-dialog {
  border-radius: 8px;
}
/deep/ .el-button--primary {
  background: #2a5dfb;
}
/deep/ .el-dialog__body {
  padding: 0 20px;
}
/deep/ .el-form-item__label {
  text-align: left;
}
.el-input {
  // width: 600px!important;
}

.modifyMarginPwd {
  // padding: 0 15px;
  // width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  height: 100%;
  margin-top: 20px;
}
.modifyMarginPwd .myModifyMarginPwd {
  float: left;
  line-height: 40px;
}
.modifyMarginPwdTitle {
  overflow: hidden;
}
.modifyMarginPwdTitle .myModifyMarginPwd p {
  font-size: 16px;
}
.modifyMarginPwdTitle .myModifyMarginPwd .tip {
  font-size: 13px;
  color: #cf4640;
  cursor: pointer;
}
.modifyMarginPwdContent {
  // width: 80%;
  padding: 15px;
}
.titles {
  padding: 15px;
  font-size: 18px;
}
.submitButton {
  text-align: center;
}
button.enBtnGetYzm {
  padding: 12px 10px;
}
</style>
