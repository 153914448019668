<template>
  <div class="safetypwd">
    <!-- <div class="title">安全密码管理</div> -->
    <div class="pdwStyle">
      <div class="pwdItems">
        <div class="left">
          <div class="lImg">
            <img src="../../../assets/images/aside/dlmm.png" alt="" width="100px" />
          </div>
          <div class="lMes">
            <div>登录密码</div>
          </div>
        </div>
        <div class="right" @click="goPage1">
          <div style="color: #999">温馨提示：安全性高的密码可以使账号更安全建议您定期更换密码；且设置一个包含数 字和字母，并长度8位以上的密码。</div>
          <div>修改密码
          <img src="../../../assets/images/aside/xgmm.png" alt=""  width="12px" /></div>
          </div>
      </div>
      <div class="pwdItems" v-if="flag.length != 0">
        <div class="left">
          <div class="lImg">
            <img src="../../../assets/images/aside/zjmm.png" alt=""  width="100px" />
          </div>
          <div class="lMes">
            <div>资金密码</div>
            
          </div>
        </div>
        <div class="right" @click="goPage2">
          <div style="color: #999">温馨提示：签约成功后，资金密码默认为123456，请及时修改资金密码。 修改密码</div>
          <div>修改密码
          <img src="../../../assets/images/aside/xgmm.png" alt=""  width="12px" /></div>
          </div>
      </div>
    </div>
    <!-- <div class="pwdItems">
      <div class="left">
        <div class="lImg">
          <img src="../../../assets/images/aside/dlmm.png" alt="" />
        </div>
        <div class="lMes">
          <div>重置登录密码</div>
          <div style="color: #ccc">用于保护账户安全</div>
        </div>
      </div>
      <div class="right" @click="goPage3">重置</div>
    </div> -->
    <!--  v-if="flag.length != 0" -->

    <!-- <div class="pwdItems" v-if="flag.length != 0">
      <div class="left">
        <div class="lImg">
          <img src="../../../assets/images/aside/zjmm.png" alt="" />
        </div>
        <div class="lMes">
          <div>重置资金密码</div>
          <div style="color: #ccc">用于保护资金安全</div>
        </div>
      </div>
      <div class="right" @click="goPage4">重置</div>
    </div> -->
    <!-- 登录密码 -->
    <pwdPopup1 ref="pwdPopup1"></pwdPopup1>
    <!-- 资金密码 -->
    <pwdPopup2 ref="pwdPopup2" @goReset="goResets"></pwdPopup2>
    <!-- 重置登陆密码 -->
    <pwdPopup3 ref="pwdPopup3"></pwdPopup3>
    <!-- 重置资金密码 -->
    <pwdPopup4 ref="pwdPopup4"></pwdPopup4>
    <el-dialog
      id="d1"
      title="温馨提示"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <p style="font-weight: 700; color: red; font-size: 18px">
        请<span style="font-size: 18px" v-if="!identificationStatus">
          先进行身份认证，然后</span
        >进行信息申报，最后进行签约
      </p>
    </el-dialog>
  </div>
</template>

<script>
// 登录密码
import pwdPopup1 from "./components/pwdPopup1";
// 资金密码
import pwdPopup2 from "./components/pwdPopup2";
// 重置登录密码
import pwdPopup3 from "./components/pwdPopup3";
// 重置资金密码
import pwdPopup4 from "./components/pwdPopup4";
export default {
  components: {
    pwdPopup1,
    pwdPopup2,
    pwdPopup3,
    pwdPopup4,
  },
  data() {
    return {
      dialogVisible: false,
      isQyBank: "",
      flag: [],
      identificationStatus: false,
    };
  },
  created() {
    this.flag = JSON.parse(localStorage.getItem("setFirmList_w"));
    // console.log()
    this.getSignInfo();
  },
  methods: {
    goPage3() {
      console.log(this.$refs.pwdPopup3);
      this.$refs.pwdPopup3.showDig();
    },
    // 认证状态查询
    getIdentificationStatus() {
      http
        .postFront(protocolFwd.param_findBaseUserAuditByUserId)
        .then((response) => {
          const { code, message, value } = response.data;
          if (Number(code) === 0) {
            // this.param_getIdentificationInfo()
            if (value == null) {
              this.identificationStatus = false;
            } else {
              this.identificationStatus = true;
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    // 签约信息查询
    getSignInfo() {
      http.postFront(protocolFwd.param_queryUserSign).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.isQyBank = value.signFlag;
        } else if (Number(code) == -180642) {
          this.isQyBank = 0;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    goPage1() {
      this.$refs.pwdPopup1.showDig();
      // this.$router.push('/accountSafe')
    },
    goPage2() {
      // this.$refs.pwdPopup2.showDig();

      if (this.isQyBank == 1 || this.isQyBank == 13) {
        this.$refs.pwdPopup2.showDig();
      } else {
        this.dialogVisible = true;
      }
      // this.$router.push('/modifyPwd')
    },
    goResets() {
      this.$refs.pwdPopup4.showDig();
    },
    goPage4() {
      // this.$refs.pwdPopup4.showDig();

      if (this.isQyBank == 1 || this.isQyBank == 13) {
        this.$refs.pwdPopup4.showDig();
      } else {
        this.dialogVisible = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ #d1 .el-dialog__title {
  color: red !important;
  font-weight: 700;
  font-size: 18px !important;
}
.pdwStyle{
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
}
.safetypwd {
  // border: 1px solid #e6ecf2;
  width: 1200px;
  // margin: 0 auto;
  // margin-bottom: 15px;
  .title {
    background-color: #f2f6fa;
    border-bottom: 1px solid #e6ecf2;
    padding: 10px;
  }
  .pwdItems {
    box-sizing: border-box;
    width: calc(50% - 20px);
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    // padding: 10px;
    border-radius: 8px;
    background-color: #F8FAFF;
    border: 1px solid #D8DEEF;
    .left {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      align-items: center;
      background-color: #ffffff;
      border-radius: 8px 8px 0 0;
      padding: 26px 0 25px 80px;
      .lImg {
        height: 100%;
        margin-right: 15px;
        display: flex;
        align-items: center;
        img {
          // width: 30px;
          // height: 30px;
          border-radius: 50%;
        }
      }
      .lMes {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        font-family: Source Han Sans SC;
        div{
          font-weight: bold;
          font-size: 16px;
          color: #222A37;
          padding-left: 90px;
        }
      }
    }
    .right {
      color: #0E5BFF;
      cursor: pointer;
      // background: #F8FAFF;
      border-radius: 0px 0px 8px 8px;
      padding: 24px 20px;
      line-height: 26px;
      img{
        vertical-align: middle;
      }
    }
  }
}
</style>
